@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;1,400&display=swap');

/* .alchemy {
    background: linear-gradient(267.45deg,#05d5ff -34.23%,#53f 99.39%)
} */

body {
    height: 100vh;
    margin: 0;
    background-size: 200% 200%;
    background-image: linear-gradient(45deg, #ff6e7f, #ffcb85, #ff7e41, #ff5e78);
    /* background-image: linear-gradient(45deg, #b46caa, #d84855, #d8b4ff, #fc90aa, #fcb4aa, #906caa, #b44855, #fcd8ff, #fff670, #ff6902); */
    animation: Gradient 15s ease infinite;
  }
  
  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
 
  .fixed-pos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    z-index: 999;
  }
 
  .button-container {
    position: fixed;
    right: 0;
    top: 10%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .logo {
    top: 0;
    width: 10%;
    height: auto;
  }



.burn-widget {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 40px auto;
  width: 300px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
}

.burn-button {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #dc3545; 
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.burn-button:hover {
  color: #fff;
  background-color: #c82333; 
  border-color: #bd2130; 
}

.refresh-button {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #1fb137; 
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.refresh-button:hover {
  color: #fff;
  background-color: #5fc823; 
  border-color: #21bd74; 
}

.video-container {
  position: relative;
  width: 75%;
  max-height: 25vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container > video {
  max-height: 25vh;
  /* left: 20%; */
  width: auto;
  height: auto;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.message-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 300px;
  transition: all 500ms;
  visibility: hidden;
  opacity: 0;
}

.message-popup.active {
  visibility: visible;
  opacity: 1;
}

.message-popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .message-popup{
    max-width: 70%;
    bottom: 10px;
    right: 10px;
  }
}


